import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '@coin/shared/util-environments';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInjector implements HttpInterceptor {
  private readonly tokenBlacklist: string[];

  constructor(private authService: AuthService) {
    this.tokenBlacklist = environment.importerAuthentication?.tokenBlacklist || [];
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request = req;

    return from(this.authService.getAccessToken()).pipe(
      switchMap(accessToken => {
        if (this.notInBlacklist(request.url)) {
          request = request.clone({
            setHeaders: {
              ...(accessToken && { Authorization: `Bearer ${accessToken}` })
            }
          });
        }
        return next.handle(request);
      })
    );
  }

  private notInBlacklist(url: string): boolean {
    return !this.tokenBlacklist.some(element => url.includes(element));
  }
}
